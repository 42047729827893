@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text{fill:none;stroke-width:1.5;stroke-linejoin:round;stroke-dasharray:130 720;stroke-dashoffset:0;-webkit-animation:stroke 21s infinite linear;animation:stroke 21s infinite linear}.text:nth-child(5n+1){stroke:#2b2d42;-webkit-animation-delay:-4.2s;animation-delay:-4.2s}.text:nth-child(5n+2){stroke:#8d99ae;-webkit-animation-delay:-8.4s;animation-delay:-8.4s}.text:nth-child(5n+3){stroke:#edf2f4;-webkit-animation-delay:-12.6s;animation-delay:-12.6s}.text:nth-child(5n+4){stroke:#ef233c;-webkit-animation-delay:-16.8s;animation-delay:-16.8s}.text:nth-child(5n+5){stroke:#d90429;-webkit-animation-delay:-21s;animation-delay:-21s}@-webkit-keyframes stroke{100%{stroke-dashoffset:-850}}@keyframes stroke{100%{stroke-dashoffset:-850}}html,body{width:100%;height:100%;position:fixed;overflow:hidden;background:linear-gradient(139deg, #fff 0%, #97a2b5 100%);box-shadow:inset 0px 0px 40px #d2d7df;margin:0}.do-font{font-family:'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-style:italic;font-weight:800;letter-spacing:1px}.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

